export enum DataHook {
  MyRewardsWrapper = 'MyRewards-wrapper',
  ButtonLearnMore = 'points-card-learn-button',
  ButtonLearnMoreWrapper = 'learn-more-wrapper',
  LoyaltyPage = 'loyalty-page-wrapper',
  RewardsList = 'RewardsList',
  RewardsListItem = 'RewardsList-item',
  RewardsListItemTitle = 'RewardsList-itemTitle',
  RewardsListItemDescription = 'RewardsList-itemDescription',
  RewardsListItemProgressBar = 'RewardsList-itemProgressBar',
  RewardsListItemButtonApplyReward = 'RewardsList-itemButton-applyReward',
  RewardsListItemButtonCouponGet = 'RewardsList-itemButton-coupon-get',
  RewardsListItemButtonCouponCopy = 'RewardsList-itemButton-coupon-copy',
  TpaModal = 'tpa-modal-content',
  RedeemPointsModal = 'redeeem-points-modal',
  CouponCodeModal = 'coupon-code-modal',

  SettingsTabs = 'settings-tabs',
  MainTabButton = 'main-tab-button',
  MainTab = 'main-tab',
  LayoutTabButton = 'layout-tab-button',
  LayoutTab = 'layout-tab',
  LayoutOptionList = 'layout-option-list',
  LayoutOptionCards = 'layout-option-cards',
  TextTabButton = 'text-tab-button',
  TextTab = 'text-tab',
  DesignTabButton = 'design-tab-button',
  DesignTab = 'design-tab',
  EmptyState = 'empty-state',
  EmptyStateTitle = 'empty-state-title',
  EmptyStateDescription = 'empty-state-description',
  DesignTextAndLinks = 'design-text-and-links',
  DesignTextAndLinksWithRewardsTab = 'design-text-and-links-with-rewards-tab',
  DesignTextAndLinksWithoutRewardsTab = 'design-text-and-links-without-rewards-tab',
  DesignBackgroundAndDrivers = 'design-background-and-drivers',
  DesignButtonsAndProgressBar = 'design-buttons-and-progress-bar',
  DesignButtonsAndProgressBarPrimaryButton = 'design-buttons-and-progress-bar-primary-button',
  DesignButtonsAndProgressBarSecondaryButton = 'design-buttons-and-progress-bar-secondary-button',
  ManageButton = 'manage-button',
  CustomizeButton = 'customize-button',
  SupportTab = 'support-tab',
  SupportTabButton = 'support-tab-button',
  ErrorState = 'error-state',
  ErrorStateTitle = 'error-state-title',
  ErrorStateDescription = 'error-state-description',
  PageTitle = 'page-title',
  PageDescription = 'page-description',
  PointsSection = 'points-section',
  RewardsSection = 'rewards-section',
  RewardsSectionTitle = 'rewards-section-title',

  PointsCardDescription = 'points-card-description',
  PointsCard = 'points-card',
  PointsCardPointsBalance = 'points-card-points-balance',
  PointsCardCurrentTierName = 'points-card-current-tier-name',
  PointsCardTotalEarnedPoints = 'points-card-total-earned-points',
  PointsCardNextTierName = 'points-card-next-tier-name',

  RewardProgressPointsNeeded = 'reward-progress-points-needed',
  RedeemPointsModalNotNowButton = 'not-now-button',
  RedeemPointsModalRedeemButton = 'redeem-button',
  ModalCloseButton = 'close-btn',
}

import { Dialog, Button, ButtonPriority, ThreeDotsLoader, Text } from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import React, { useEffect, useState } from 'react';

import { style, classes } from './get-coupon-modal.st.css';
import { SimpleReward } from '../../../../../types/domain';
import { ModalType } from '../../hocs/modal-context';
import { useCoupons, useLoyaltyProgram, useRewards } from '../../hooks/context-hooks';
import { RequestStatus } from '../../../../../types/store';
import { useModal } from '../../hooks/use-modal';
import { DataHook } from '../../../../../types/data-hook';

export interface GetCouponModalType {
  type: ModalType.GET_COUPON;
  props: {
    reward: SimpleReward;
    onConfirm: () => void;
  };
}
interface GetCouponModalProps {
  onClose: () => void;
  onConfirm: () => void;
  reward: SimpleReward;
}

export const GetCouponModal: React.FC<GetCouponModalProps> = ({ onClose, onConfirm, reward }) => {
  const { t } = useTranslation();
  const { redeemCouponStatus } = useCoupons();
  const { simpleRewards } = useRewards();
  const { showModal } = useModal();
  const { program } = useLoyaltyProgram();
  const { isMobile } = useEnvironment();
  const [rewardsLength] = useState(simpleRewards.length);
  const pointsName = program.pointDefinition?.customName;
  const { requiredPoints } = reward;

  useEffect(() => {
    if (redeemCouponStatus === RequestStatus.SUCCESS && simpleRewards.length > rewardsLength) {
      onClose();
      showModal({
        type: ModalType.COUPON_CODE,
        props: {
          couponCode: simpleRewards[0].couponCode!,
          couponName: simpleRewards[0].couponName!,
        },
      });
    }
  }, [simpleRewards, redeemCouponStatus]);

  return (
    <Dialog isOpen={true} onClose={onClose} notFullscreenOnMobile={isMobile} data-hook={DataHook.RedeemPointsModal}>
      <div className={style(classes.modal, { isMobile })}>
        <div className={classes.textsContainer}>
          <Text tagName="h2" className={classes.header}>
            {pointsName
              ? t('app.get-coupon-modal.title-custom-points', { pointsRequired: requiredPoints, pointsName })
              : t('app.get-coupon-modal.title', { pointsRequired: requiredPoints })}
          </Text>
          <Text tagName="p" className={classes.description}>
            {t('app.get-coupon-modal.description', { couponName: reward.title })}
          </Text>
        </div>
        <div className={classes.buttons}>
          <Button
            priority={ButtonPriority.basicSecondary}
            upgrade
            className={classes.secondaryButton}
            data-hook={DataHook.RedeemPointsModalNotNowButton}
            onClick={onClose}
          >
            {t('app.get-coupon-modal.cancel-button')}
          </Button>
          <Button
            upgrade
            className={classes.primaryButton}
            onClick={onConfirm}
            data-hook={DataHook.RedeemPointsModalRedeemButton}
            disabled={redeemCouponStatus === RequestStatus.LOADING}
          >
            {redeemCouponStatus === RequestStatus.LOADING ? (
              <ThreeDotsLoader />
            ) : (
              t('app.get-coupon-modal.redeem-button')
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

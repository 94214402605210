import { LoyaltyCoupon, Status, CouponReference } from '@wix/ambassador-loyalty-v1-coupon/types';
import { Reward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { RewardOrigin, SimpleReward } from '../../types/domain';

export const toSimpleRewards = (
  rewards: Reward[],
  coupons: CouponReference[],
  claimedCoupons: LoyaltyCoupon[],
  translations: ControllerFlowAPI['translations'],
  buildRewardDescription: (reward: Reward, configIndex: number, coupon?: CouponReference) => string,
  buildClaimedCouponTitle: (claimedCoupon: LoyaltyCoupon, rewardName?: string) => string,
): SimpleReward[] => {
  const simpleRewards = rewards.reduce<SimpleReward[]>((results, reward) => {
    if (!reward.active) {
      return results;
    }

    const configRewards: SimpleReward[] = [];
    const commonRewardParams = {
      id: reward.id,
      title: reward.name,
      type: reward.type,
      dateCreated: reward.createdDate,
      couponActivated: false,
      rewardOrigin: RewardOrigin.USER,
    };

    if (reward.type === RewardType.COUPON) {
      const coupon = coupons.find(({ couponId }) => couponId === reward.coupon?.templateId);
      if (!coupon) {
        return results;
      }

      reward.coupon?.configs?.forEach(({ tierId, requiredPoints }, index) => {
        configRewards.push({
          ...commonRewardParams,
          description: buildRewardDescription(reward, index, coupon),
          requiredPoints: requiredPoints ?? 0,
          tierId,
        });
      });
    } else if (reward.type === RewardType.DISCOUNT_AMOUNT) {
      reward.discountAmount?.configsByTier?.forEach(({ tierId, costInPoints }, index) => {
        configRewards.push({
          ...commonRewardParams,
          description: buildRewardDescription(reward, index),
          requiredPoints: costInPoints ?? 0,
          tierId,
        });
      });
    } else if (reward.type === RewardType.COUPON_REWARD) {
      const { couponReward } = reward;
      const configsByTier =
        couponReward?.percentage?.configsByTier ??
        couponReward?.fixedAmount?.configsByTier ??
        couponReward?.freeShipping?.configsByTier;

      configsByTier?.forEach(({ tierId, costInPoints }, index) => {
        configRewards.push({
          ...commonRewardParams,
          description: buildRewardDescription(reward, index),
          requiredPoints: costInPoints ?? 0,
          tierId,
        });
      });
    }

    return [...results, ...configRewards];
  }, []);

  if (claimedCoupons.length) {
    simpleRewards.push(
      ...claimedCoupons
        .filter((coupon) => coupon.status === Status.ACTIVE)
        .map<SimpleReward>((coupon) => ({
          id: coupon.id,
          title: buildClaimedCouponTitle(coupon),
          description: translations.t('app.reward-description.coupon-claimed', {
            code: coupon.couponReference?.code,
          }),
          type: RewardType.COUPON,
          dateCreated: coupon.createdDate,
          couponActivated: true,
          couponCode: coupon.couponReference?.code,
          couponName: coupon.rewardName,
          rewardOrigin: RewardOrigin.MEMBER,
        })),
    );
  }

  return simpleRewards;
};

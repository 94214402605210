import React, { createContext, useEffect } from 'react';

import { ControllerProps } from '../..';
import { RequestStatus } from '../../../../../types/store';

export const WidgetContext = createContext<ControllerProps>(null!);

export const WidgetContextProvider: React.FC<ControllerProps> = (props) => {
  useEffect(() => {
    const { redeemCouponStatus } = props.couponsConfig;
    if (redeemCouponStatus === RequestStatus.SUCCESS || redeemCouponStatus === RequestStatus.FAILURE) {
      props.couponsHandlers.resetRedeemCouponStatus();
    }
  }, [props.couponsConfig.redeemCouponStatus]);

  useEffect(() => {
    const { fetchAccountStatus } = props.accountConfig;
    if (fetchAccountStatus === RequestStatus.SUCCESS || fetchAccountStatus === RequestStatus.FAILURE) {
      props.accountHandlers.resetFetchAccountStatus();
    }
  }, [props.accountConfig.fetchAccountStatus]);

  useEffect(() => {
    const { redeemRewardStatus } = props.rewardsConfig;
    if (redeemRewardStatus === RequestStatus.SUCCESS || redeemRewardStatus === RequestStatus.FAILURE) {
      props.rewardsHandlers.resetRedeemRewardStatus();
    }
  }, [props.rewardsConfig.redeemRewardStatus]);

  return <WidgetContext.Provider value={props}>{props.children}</WidgetContext.Provider>;
};

import React from 'react';
import { useStyles } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Divider } from './divider';
import { ListItem } from './list-item';
import stylesParams, { RewardsLayout } from '../stylesParams';
import { style, classes } from './rewards-list.st.css';
import { useAccount, useRewards } from './hooks/context-hooks';
import { DataHook } from '../../../types/data-hook';

export const RewardsList = () => {
  const styles = useStyles();
  const { simpleRewards } = useRewards();
  const { isMobile } = useEnvironment();
  const { account } = useAccount();
  const rewardsLayout = styles.get(stylesParams.rewardsLayout);
  const tierId = account.tierId;

  const rewards = simpleRewards
    ?.filter((reward) => reward.tierId === tierId || reward.couponActivated)
    .map((reward) => <ListItem key={reward.id!} reward={reward} />);

  if (rewardsLayout === RewardsLayout.Cards) {
    return (
      <div
        data-hook={DataHook.RewardsList}
        className={style(classes.rootCards, {
          isMobile,
        })}
      >
        {rewards}
      </div>
    );
  }

  return (
    <div data-hook={DataHook.RewardsList}>
      <Divider />
      {rewards}
    </div>
  );
};

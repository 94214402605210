import { StyleParamType, wixColorParam, wixFontParam, createStylesParams } from '@wix/tpa-settings';
import { Experiments } from '../../constants';

export enum Alignment {
  Left = 0,
  Center = 1,
  Right = 2,
}

export enum RewardsLayout {
  List = 0,
  Cards = 1,
}

type IStylesParams = {
  pageTitleFont: StyleParamType.Font;
  pageTitleColor: StyleParamType.Color;
  pageDescriptionFont: StyleParamType.Font;
  pageDescriptionColor: StyleParamType.Color;
  rewardsListSectionTitleFont: StyleParamType.Font;
  rewardsListSectionTitleColor: StyleParamType.Color;

  totalPointsTotalNumberFont: StyleParamType.Font;
  totalPointsTotalNumberColor: StyleParamType.Color;
  totalPointsTitleFont: StyleParamType.Font;
  totalPointsTitleColor: StyleParamType.Color;
  totalPointsLinkFont: StyleParamType.Font;
  totalPointsLinkColor: StyleParamType.Color;

  tiersCurrentTierTitleFont: StyleParamType.Font;
  tiersCurrentTierTitleColor: StyleParamType.Color;
  tiersTierNameFont: StyleParamType.Font;
  tiersTierNameColor: StyleParamType.Color;
  tiersProgressBarFont: StyleParamType.Font;
  tiersProgressBarColor: StyleParamType.Color;

  rewardsTotalNumberFont: StyleParamType.Font; // Used as default for "totalPointsTotalNumberFont"
  rewardsTotalNumberColor: StyleParamType.Color; // Used as default for "totalPointsTotalNumberColor"
  rewardsSectionTitleFont: StyleParamType.Font; // Used as default for "rewardsListSectionTitleFont"
  rewardsSectionTitleColor: StyleParamType.Color; // Used as default for "rewardsListSectionTitleColor"
  rewardsRewardTitleFont: StyleParamType.Font;
  rewardsRewardTitleColor: StyleParamType.Color;
  rewardsRewardTextFont: StyleParamType.Font;
  rewardsRewardTextColor: StyleParamType.Color;

  emptyStateTitleFont: StyleParamType.Font;
  emptyStateTitleColor: StyleParamType.Color;
  emptyStateSubtitleFont: StyleParamType.Font;
  emptyStateSubtitleColor: StyleParamType.Color;

  primaryButtonTextFont: StyleParamType.Font;
  primaryButtonTextColor: StyleParamType.Color;
  primaryButtonBorderRadius: StyleParamType.Number;
  primaryButtonBorderWidth: StyleParamType.Number;
  primaryButtonBorderColor: StyleParamType.Color;
  primaryButtonBackgroundColor: StyleParamType.Color;

  secondaryButtonTextFont: StyleParamType.Font;
  secondaryButtonTextColor: StyleParamType.Color;
  secondaryButtonBorderRadius: StyleParamType.Number;
  secondaryButtonBorderWidth: StyleParamType.Number;
  secondaryButtonBorderColor: StyleParamType.Color;
  secondaryButtonBackgroundColor: StyleParamType.Color;

  buttonBackgroundColor: StyleParamType.Color; // Used as default for "primaryButtonBackgroundColor"
  buttonBorderColor: StyleParamType.Color; // Used as default for "primaryButtonBorderColor"
  buttonBorderWidth: StyleParamType.Number; // Used as default for "primaryButtonBorderWidth"
  buttonBorderRadius: StyleParamType.Number; // Used as default for "primaryButtonBorderRadius"
  buttonTextFont: StyleParamType.Font; // Used as default for "primaryButtonTextFont" and "secondaryButtonTextFont"
  buttonTextColor: StyleParamType.Color; // Used as default for "primaryButtonTextColor"

  progressBarTextFont: StyleParamType.Font;
  progressBarTextColor: StyleParamType.Color;
  progressBarColor: StyleParamType.Color;
  progressBarBackgroundColor: StyleParamType.Color;

  backgroundColor: StyleParamType.Color;
  backgroundBorderWidth: StyleParamType.Number;
  backgroundBorderColor: StyleParamType.Color;

  rewardsBackgroundColor: StyleParamType.Color;
  rewardsBackgroundBorderWidth: StyleParamType.Number;
  rewardsBackgroundBorderColor: StyleParamType.Color; // Used as default for "pointsBackgroundBorderColor"

  pointsBackgroundBorderColor: StyleParamType.Color;
  totalPointsBorderRadius: StyleParamType.Number;

  rewardsPendingBackgroundColor: StyleParamType.Color;
  rewardsPendingBorderWidth: StyleParamType.Number;
  rewardsPendingBorderColor: StyleParamType.Color;
  rewardsPendingBorderRadius: StyleParamType.Number;
  rewardsBorderChanged: StyleParamType.Boolean;

  dividerColor: StyleParamType.Color;
  dividerWidth: StyleParamType.Number;
  dividerChanged: StyleParamType.Boolean;

  rewardsLayout: StyleParamType.Number;

  listSpacing: StyleParamType.Number;
  listRewardsHorizontalPadding: StyleParamType.Number;
  listRewardsVerticalPadding: StyleParamType.Number;

  cardsSpacing: StyleParamType.Number;
  cardsRewardsHorizontalPadding: StyleParamType.Number;
  cardsRewardsVerticalPadding: StyleParamType.Number;
  cardsItemsAlignment: StyleParamType.Number;
};

export default createStylesParams<IStylesParams>({
  pageTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 26,
    }),
  },
  pageTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  pageDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 15,
    }),
  },
  pageDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  rewardsListSectionTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: (params) => {
      const sectionTitle = params.getStyleParamValue({ type: StyleParamType.Font, key: 'rewardsSectionTitleFont' });
      return (
        sectionTitle ??
        wixFontParam('Body-M', {
          size: 20,
        })(params)
      );
    },
  },
  rewardsListSectionTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: (params) => {
      const sectionTitle = params.getStyleParamValue({ type: StyleParamType.Color, key: 'rewardsSectionTitleColor' });
      return sectionTitle ?? wixColorParam('color-5')(params);
    },
  },

  totalPointsTotalNumberFont: {
    type: StyleParamType.Font,
    getDefaultValue: (params) => {
      const totalNumber = params.getStyleParamValue({ type: StyleParamType.Font, key: 'rewardsTotalNumberFont' });
      return (
        totalNumber ??
        wixFontParam('Body-M', {
          size: 40,
        })(params)
      );
    },
  },
  totalPointsTotalNumberColor: {
    type: StyleParamType.Color,
    getDefaultValue: (params) => {
      const totalNumber = params.getStyleParamValue({ type: StyleParamType.Color, key: 'rewardsTotalNumberColor' });
      return totalNumber ?? wixColorParam('color-5')(params);
    },
  },
  totalPointsTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: (params) => {
      const totalTitle = params.getStyleParamValue({ type: StyleParamType.Font, key: 'rewardsListSectionTitleFont' });
      return (
        totalTitle ??
        wixFontParam('Body-M', {
          size: params.experiments.enabled(Experiments.ShowTiers) ? 16 : 20,
        })(params)
      );
    },
  },
  totalPointsTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: (params) => {
      const totalTitle = params.getStyleParamValue({ type: StyleParamType.Color, key: 'rewardsListSectionTitleColor' });
      return totalTitle ?? wixColorParam('color-5')(params);
    },
  },
  totalPointsLinkFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 15 }),
  },
  totalPointsLinkColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },

  tiersCurrentTierTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  },
  tiersCurrentTierTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  tiersTierNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 24 }),
  },
  tiersTierNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  tiersProgressBarFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  tiersProgressBarColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },

  rewardsTotalNumberFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Title', {
      size: 40,
    }),
  },
  rewardsTotalNumberColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  rewardsSectionTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  rewardsSectionTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  rewardsRewardTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  rewardsRewardTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  rewardsRewardTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 15,
    }),
  },
  rewardsRewardTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },

  emptyStateTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 24,
    }),
  },
  emptyStateTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  emptyStateSubtitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  emptyStateSubtitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },

  primaryButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: (params) => {
      const button = params.getStyleParamValue({ type: StyleParamType.Font, key: 'buttonTextFont' });
      return (
        button ??
        wixFontParam('Body-M', {
          size: 16,
        })(params)
      );
    },
  },
  primaryButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: (params) => {
      const button = params.getStyleParamValue({ type: StyleParamType.Color, key: 'buttonTextColor' });
      return button ?? wixColorParam('color-1')(params);
    },
  },
  primaryButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: (params) => {
      const button = params.getStyleParamValue({ type: StyleParamType.Number, key: 'buttonBorderRadius' });
      return button ?? 0;
    },
  },
  primaryButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: (params) => {
      const button = params.getStyleParamValue({ type: StyleParamType.Number, key: 'buttonBorderWidth' });
      return button ?? 0;
    },
  },
  primaryButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: (params) => {
      const button = params.getStyleParamValue({ type: StyleParamType.Color, key: 'buttonBorderColor' });
      return button ?? wixColorParam('color-8')(params);
    },
  },
  primaryButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: (params) => {
      const button = params.getStyleParamValue({ type: StyleParamType.Color, key: 'buttonBackgroundColor' });
      return button ?? wixColorParam('color-8')(params);
    },
  },

  secondaryButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: (params) => {
      const button = params.getStyleParamValue({ type: StyleParamType.Font, key: 'buttonTextFont' });
      return (
        button ??
        wixFontParam('Body-M', {
          size: 16,
        })(params)
      );
    },
  },
  secondaryButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  secondaryButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  secondaryButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  secondaryButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  secondaryButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },

  buttonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  buttonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  buttonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  buttonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },

  progressBarTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  progressBarTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  progressBarColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  progressBarBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4', 0.3),
  },

  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  backgroundBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  backgroundBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },

  rewardsBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.2),
  },
  rewardsBackgroundBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  rewardsBackgroundBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0),
  },

  pointsBackgroundBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: (params) => {
      const borderColor = params.getStyleParamValue({
        type: StyleParamType.Color,
        key: 'rewardsBackgroundBorderColor',
      });
      return borderColor ?? wixColorParam('color-5', 1)(params);
    },
  },
  totalPointsBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },

  rewardsPendingBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0),
  },
  rewardsPendingBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  rewardsPendingBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  rewardsPendingBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  rewardsBorderChanged: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },

  dividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  dividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  dividerChanged: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },

  rewardsLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => RewardsLayout.List,
  },

  listSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  listRewardsHorizontalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  listRewardsVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
  },

  cardsSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: () => 24,
  },
  cardsRewardsHorizontalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  cardsRewardsVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 42 : 64),
  },
  cardsItemsAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.Center,
  },
});

import React, { useState } from 'react';
import { useEnvironment, useBi } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import { RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { Text } from 'wix-ui-tpa';
import { loyaltyUouClick } from '@wix/bi-logger-loyalty-uou/v2';

import { SimpleReward } from '../../../types/domain';
import { Divider } from './divider';
import { style, classes } from './list-item.st.css';
import { ListItemButton } from './list-item-button';
import stylesParams, { Alignment, RewardsLayout } from '../stylesParams';
import { useModal } from './hooks/use-modal';
import { ModalType } from './hocs/modal-context';
import { useCouponsActions, useRewards, useRewardsActions } from './hooks/context-hooks';
import { RequestStatus } from '../../../types/store';
import { DataHook } from '../../../types/data-hook';

const alignmentToStateMap = {
  [Alignment.Left]: 'left',
  [Alignment.Center]: 'center',
  [Alignment.Right]: 'right',
};
interface ListItemProps {
  reward: SimpleReward;
}

export const ListItem: React.FC<ListItemProps> = ({ reward }) => {
  const styles = useStyles();
  const { showModal } = useModal();
  const { redeemCoupon } = useCouponsActions();
  const { redeemReward } = useRewardsActions();
  const { redeemRewardStatus } = useRewards();
  const [rewardId, setRewardId] = useState<string>();
  const [copied, setCopied] = useState(false);
  const { isMobile, isRTL } = useEnvironment();
  const bi = useBi();
  const rewardsLayout = styles.get(stylesParams.rewardsLayout);
  const cardsAlignment = styles.get(stylesParams.cardsItemsAlignment) as Alignment;
  const isLoadingCorrectReward = rewardId === reward.id && redeemRewardStatus === RequestStatus.LOADING;

  const handleRewardOnClick = () => {
    if (reward.couponActivated) {
      navigator.clipboard.writeText(reward.couponCode!);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 5000);
      bi.report(
        loyaltyUouClick({
          value: 'copy_coupon_code',
          origin: 'my_rewards_page',
        }),
      );
      return;
    }

    if (reward.type === RewardType.COUPON || reward.type === RewardType.COUPON_REWARD) {
      bi.report(
        loyaltyUouClick({
          value: 'redeem_coupon_reward',
          origin: 'my_rewards_page',
        }),
      );
      showModal({
        type: ModalType.GET_COUPON,
        props: {
          reward,
          onConfirm: () => {
            bi.report(
              loyaltyUouClick({
                value: 'redeem_coupon_reward',
                origin: 'popup',
              }),
            );
            redeemCoupon(reward);
          },
        },
      });
      return;
    }

    bi.report(
      loyaltyUouClick({
        value: 'redeem_reward',
        origin: 'my_rewards_page',
      }),
    );
    setRewardId(reward.id!);
    redeemReward(reward);
  };

  if (rewardsLayout === RewardsLayout.Cards) {
    return (
      <div
        data-hook={DataHook.RewardsListItem}
        className={style(classes.itemCards, {
          alignment: alignmentToStateMap[cardsAlignment],
          isRTL,
        })}
      >
        <div className={classes.firstColumns}>
          <div
            className={style(classes.itemColumnA, {
              alignment: alignmentToStateMap[cardsAlignment],
            })}
          >
            <Text tagName="h3" data-hook={DataHook.RewardsListItemTitle} className={classes.title}>
              {reward.title}
            </Text>
            <Text tagName="p" data-hook={DataHook.RewardsListItemDescription} className={classes.description}>
              {reward.description}
            </Text>
          </div>
        </div>
        <div className={classes.itemColumnCCards}>
          <ListItemButton
            showCopied={copied}
            isLoading={isLoadingCorrectReward}
            reward={reward}
            onClick={handleRewardOnClick}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        data-hook={DataHook.RewardsListItem}
        className={style(classes.itemList, {
          isMobile,
        })}
      >
        <div className={classes.firstColumns}>
          <div className={classes.itemColumnA}>
            <Text tagName="h3" data-hook={DataHook.RewardsListItemTitle} className={classes.title}>
              {reward.title}
            </Text>
            <Text tagName="p" data-hook={DataHook.RewardsListItemDescription} className={classes.description}>
              {reward.description}
            </Text>
          </div>
        </div>
        <div className={classes.itemColumnC}>
          <ListItemButton
            showCopied={copied}
            isLoading={isLoadingCorrectReward}
            reward={reward}
            onClick={handleRewardOnClick}
          />
        </div>
      </div>
      <Divider />
    </>
  );
};

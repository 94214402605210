import { useContext } from 'react';

import { WidgetContext } from '../hocs/widget-context';

export const useAccount = () => useContext(WidgetContext).accountConfig;

export const useAccountActions = () => useContext(WidgetContext).accountHandlers;

export const useCoupons = () => useContext(WidgetContext).couponsConfig;

export const useCouponsActions = () => useContext(WidgetContext).couponsHandlers;

export const useRewards = () => useContext(WidgetContext).rewardsConfig;

export const useRewardsActions = () => useContext(WidgetContext).rewardsHandlers;

export const useNavigationActions = () => useContext(WidgetContext).navigationHandlers;

export const useLoyaltyProgram = () => useContext(WidgetContext).loyaltyProgramConfig;

export const useToast = () => useContext(WidgetContext).toastConfig;

export const useToastActions = () => useContext(WidgetContext).toastHandlers;

export const useTiersProgram = () => useContext(WidgetContext).tiersProgramConfig;

import React from 'react';
import { WidgetProps, useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { ProgramStatus } from '@wix/ambassador-loyalty-v1-program/types';
import { Text } from 'wix-ui-tpa';
import { Status } from '@wix/ambassador-loyalty-v1-tier/types';

import settingsParams from '../settingsParams';
import { style, classes } from './widget.st.css';
import { EmptyState } from './empty-state';
import { PointsCard } from './points-card';
import { RewardsList } from './rewards-list';
import { RewardsTabState } from '../../../types/settings';
import { ErrorState } from './error-state';
import { ModalProvider } from './hocs/modal-context';
import { WidgetContextProvider } from './hocs/widget-context/widget-context';
import { getActionHandlers } from './store';
import {
  AccountConfigProps,
  CouponsConfigProps,
  LoyaltyProgramConfigProps,
  ToastConfigProps,
  TiersProgramConfigProps,
} from './store/slices';
import { RewardsConfigProps } from './store/slices/rewards/slice';
import { DataHook } from '../../../types/data-hook';
import { Toast } from './toast';
import { Experiments } from '../../../constants';
import { TierCard } from './tier-card';

export type ControllerProps = {
  hasError: boolean;
  rewardsTabState: RewardsTabState;
  accountConfig: AccountConfigProps;
  accountHandlers: ReturnType<typeof getActionHandlers>['accountHandlers'];
  couponsConfig: CouponsConfigProps;
  couponsHandlers: ReturnType<typeof getActionHandlers>['couponsHandlers'];
  rewardsConfig: RewardsConfigProps;
  rewardsHandlers: ReturnType<typeof getActionHandlers>['rewardsHandlers'];
  navigationHandlers: ReturnType<typeof getActionHandlers>['navigationHandlers'];
  loyaltyProgramConfig: LoyaltyProgramConfigProps;
  toastConfig: ToastConfigProps;
  toastHandlers: ReturnType<typeof getActionHandlers>['toastHandlers'];
  tiersProgramConfig: TiersProgramConfigProps;
};

const Widget: React.FC<WidgetProps<ControllerProps>> = (props) => {
  const settings = useSettings();
  const { isMobile, isRTL } = useEnvironment();
  const { experiments } = useExperiments();
  const tiersEnabled = experiments.enabled(Experiments.ShowTiers);
  const showTiers = tiersEnabled && props.tiersProgramConfig?.programSettings?.status === Status.ACTIVE;

  if (props.hasError) {
    return <ErrorState />;
  }

  return (
    <WidgetContextProvider {...props}>
      <ModalProvider>
        <Toast />
        <div className={style(classes.root, { isMobile, isRTL })} data-hook={DataHook.MyRewardsWrapper}>
          <div className={classes.header}>
            <Text tagName="h1" data-hook={DataHook.PageTitle} className={classes.pageTitle}>
              {settings.get(settingsParams.pageTitle)}
            </Text>
            <Text tagName="p" data-hook={DataHook.PageDescription} className={classes.pageDescription}>
              {settings.get(settingsParams.pageDescription)}
            </Text>
          </div>
          <div
            data-hook={DataHook.PointsSection}
            className={style(classes.pointsSection, {
              tiersEnabled,
              showTiers,
            })}
          >
            {showTiers && <TierCard />}
            <PointsCard />
          </div>
          <div data-hook={DataHook.RewardsSection} className={classes.rewardsSection}>
            <Text tagName="h2" data-hook={DataHook.RewardsSectionTitle} className={classes.rewardsListSectionTitle}>
              {settings.get(settingsParams.sectionTitle)}
            </Text>
            <div className={classes.rewardsSectionContent}>
              {props.loyaltyProgramConfig.program.status !== ProgramStatus.ACTIVE ||
              props.rewardsConfig.simpleRewards.length === 0 ||
              props.rewardsTabState === RewardsTabState.Empty ? (
                <EmptyState />
              ) : (
                <RewardsList />
              )}
            </div>
          </div>
        </div>
      </ModalProvider>
    </WidgetContextProvider>
  );
};

export default Widget;

import React from 'react';
import { Button, ButtonPriority, ButtonSize, ThreeDotsLoader } from 'wix-ui-tpa';
import { useSettings } from '@wix/tpa-settings/react';
import { RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { Confirm as ConfirmIcon } from '@wix/wix-ui-icons-common';

import settingsParams from '../settingsParams';
import { classes, style } from './list-item-button.st.css';
import { SimpleReward } from '../../../types/domain';
import { RewardProgress } from './reward-progress';
import { DataHook } from '../../../types/data-hook';
import { useAccount } from './hooks/context-hooks';
import { useEnvironment } from '@wix/yoshi-flow-editor';

interface ListItemProps {
  onClick: () => void;
  reward: SimpleReward;
  isLoading?: boolean;
  showCopied?: boolean;
}

export const ListItemButton: React.FC<ListItemProps> = ({ onClick, reward, isLoading, showCopied }) => {
  const settings = useSettings();
  const { account } = useAccount();
  const { isMobile } = useEnvironment();
  const isDiscount = reward.type === RewardType.DISCOUNT_AMOUNT;
  const isCoupon = reward.type === RewardType.COUPON || RewardType.COUPON_REWARD;
  const isActivated = reward.couponActivated;
  const hasEnoughPoints = (account.pointsBalance ?? 0) >= (reward.requiredPoints ?? 0);

  if (isDiscount && hasEnoughPoints) {
    return (
      <Button
        data-hook={DataHook.RewardsListItemButtonApplyReward}
        className={style(classes.primaryButton, { isMobile })}
        size={ButtonSize.small}
        onClick={onClick}
        upgrade
        disabled={isLoading}
      >
        <div className={isLoading ? classes.container : ''}>
          {isLoading && <ThreeDotsLoader className={classes.loader} />}
          <div className={isLoading ? classes.buttonText : ''}>{settings.get(settingsParams.button)}</div>
        </div>
      </Button>
    );
  }

  if (isCoupon && isActivated) {
    return (
      <Button
        priority={ButtonPriority.secondary}
        data-hook={DataHook.RewardsListItemButtonCouponCopy}
        size={ButtonSize.small}
        onClick={onClick}
        upgrade
        className={style(classes.secondaryButton, { isMobile })}
      >
        <div className={showCopied ? classes.container : ''}>
          {showCopied && <ConfirmIcon size="24px" className={classes.confirmIcon} />}
          <div className={showCopied ? classes.buttonText : ''}>{settings.get(settingsParams.copyButton)}</div>
        </div>
      </Button>
    );
  }

  if (isCoupon && hasEnoughPoints) {
    return (
      <Button
        data-hook={DataHook.RewardsListItemButtonCouponGet}
        className={style(classes.primaryButton, { isMobile })}
        size={ButtonSize.small}
        onClick={onClick}
        upgrade
      >
        {settings.get(settingsParams.getButton)}
      </Button>
    );
  }

  return <RewardProgress reward={reward} />;
};
